// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3 9a1 1 0 110-2 1 1 0 010 2zM7 8a1 1 0 102 0 1 1 0 00-2 0zM12 8a1 1 0 102 0 1 1 0 00-2 0z","fillOpacity":0.9}}]};

const EllipsisIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'ellipsis',
    icon: element,
  },
);

EllipsisIcon.displayName = 'EllipsisIcon';

export default EllipsisIcon;
