// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3.54 6.46l.92-.92L8 9.08l3.54-3.54.92.92L8 10.92 3.54 6.46z","fillOpacity":0.9}}]};

const ChevronDownIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-down',
    icon: element,
  },
);

ChevronDownIcon.displayName = 'ChevronDownIcon';

export default ChevronDownIcon;
