// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M13.04 4.46l-.92-.92L7.66 8l4.46 4.46.92-.92L9.5 8l3.54-3.54z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M8.04 4.46l-.92-.92L2.66 8l4.46 4.46.92-.92L4.5 8l3.54-3.54z","fillOpacity":0.9}}]};

const ChevronLeftDoubleIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-left-double',
    icon: element,
  },
);

ChevronLeftDoubleIcon.displayName = 'ChevronLeftDoubleIcon';

export default ChevronLeftDoubleIcon;
