// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M7.5 11V8.5H5v-1h2.5V5h1v2.5H11v1H8.5V11h-1z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M3 14a1 1 0 01-1-1V3a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1H3zm0-1h10V3H3v10z","fillOpacity":0.9}}]};

const AddRectangleIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'add-rectangle',
    icon: element,
  },
);

AddRectangleIcon.displayName = 'AddRectangleIcon';

export default AddRectangleIcon;
