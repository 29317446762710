// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M15 8A7 7 0 101 8a7 7 0 0014 0zM8.5 4v5.5h-1V4h1zm-1.1 7h1.2v1.2H7.4V11z","fillOpacity":0.9}}]};

const ErrorCircleFilledIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'error-circle-filled',
    icon: element,
  },
);

ErrorCircleFilledIcon.displayName = 'ErrorCircleFilledIcon';

export default ErrorCircleFilledIcon;
