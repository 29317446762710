// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12.4 4.46l-.91-.92L7.03 8l4.46 4.46.92-.92L8.86 8l3.55-3.54zM4.8 4v8h1.3V4H4.8z","fillOpacity":0.9}}]};

const PageFirstIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'page-first',
    icon: element,
  },
);

PageFirstIcon.displayName = 'PageFirstIcon';

export default PageFirstIcon;
