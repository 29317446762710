// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M9.51 10.22a4.76 4.76 0 11.7-.7l3.54 3.52-.7.71-3.54-3.53zm.77-3.7a3.76 3.76 0 10-7.53 0 3.76 3.76 0 007.53 0z","fillOpacity":0.9}}]};

const SearchIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'search',
    icon: element,
  },
);

SearchIcon.displayName = 'SearchIcon';

export default SearchIcon;
