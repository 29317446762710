// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M5 8.5h6v-1H5v1z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M3 14a1 1 0 01-1-1V3a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1H3zm0-1h10V3H3v10z","fillOpacity":0.9}}]};

const MinusRectangleIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'minus-rectangle',
    icon: element,
  },
);

MinusRectangleIcon.displayName = 'MinusRectangleIcon';

export default MinusRectangleIcon;
