// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M8 11.5a3.5 3.5 0 110-7 3.5 3.5 0 010 7zM8 13A5 5 0 108 3a5 5 0 000 10z","fillOpacity":0.9}}]};

const RoundIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'round',
    icon: element,
  },
);

RoundIcon.displayName = 'RoundIcon';

export default RoundIcon;
