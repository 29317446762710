// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M6 12V6h1v6H6zM9 6v6h1V6H9z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M10.5 3H14v1h-1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V4H2V3h3.5V1.8c0-.44.36-.8.8-.8h3.4c.44 0 .8.36.8.8V3zm-4 0h3V2h-3v1zM4 4v10h8V4H4z","fillOpacity":0.9}}]};

const DeleteIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'delete',
    icon: element,
  },
);

DeleteIcon.displayName = 'DeleteIcon';

export default DeleteIcon;
