// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"g","attrs":{"fill":"currentColor","opacity":0.9,"fillOpacity":0.9},"children":[{"tag":"path","attrs":{"d":"M14.13 4.95L10.9 1.71l.7-.71 3.25 3.24-.7.71zM5.97 13.11l-3.61.72a.3.3 0 01-.35-.35l.72-3.61 7.3-7.3 3.24 3.24-7.3 7.3zm5.89-7.3l-1.83-1.83-6.38 6.38-.46 2.29 2.29-.46 6.38-6.38zM15 11h-4v1h4v-1zM15 13H8.5v1H15v-1z"}}]}]};

const Edit1Icon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'edit-1',
    icon: element,
  },
);

Edit1Icon.displayName = 'Edit1Icon';

export default Edit1Icon;
