// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M9.54 3.54l.92.92L6.92 8l3.54 3.54-.92.92L5.08 8l4.46-4.46z","fillOpacity":0.9}}]};

const ChevronLeftIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-left',
    icon: element,
  },
);

ChevronLeftIcon.displayName = 'ChevronLeftIcon';

export default ChevronLeftIcon;
