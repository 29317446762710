// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M6.2 5.2L9 8l-2.8 2.8.71.7 3.5-3.5-3.5-3.5-.7.7z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M8 15A7 7 0 118 1a7 7 0 010 14zm0-1A6 6 0 108 2a6 6 0 000 12z","fillOpacity":0.9}}]};

const ChevronRightCircleIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-right-circle',
    icon: element,
  },
);

ChevronRightCircleIcon.displayName = 'ChevronRightCircleIcon';

export default ChevronRightCircleIcon;
