// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M2.96 11.54l.92.92L8.34 8 3.88 3.54l-.92.92L6.5 8l-3.54 3.54zm5 0l.92.92L13.34 8 8.88 3.54l-.92.92L11.5 8l-3.54 3.54z","fillOpacity":0.9}}]};

const ChevronRightDoubleIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-right-double',
    icon: element,
  },
);

ChevronRightDoubleIcon.displayName = 'ChevronRightDoubleIcon';

export default ChevronRightDoubleIcon;
