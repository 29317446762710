// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3.6 11.54l.91.92L8.97 8 4.51 3.54l-.92.92L7.14 8l-3.55 3.54zm7.6.46V4H9.9v8h1.3z","fillOpacity":0.9}}]};

const PageLastIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'page-last',
    icon: element,
  },
);

PageLastIcon.displayName = 'PageLastIcon';

export default PageLastIcon;
