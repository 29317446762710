// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3.74 6.68L7.5 2.9v8.59h1V2.91l3.76 3.77.71-.7-4.62-4.63a.5.5 0 00-.7 0L3.03 5.97l.7.7z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M2 11v2a1 1 0 001 1h10a1 1 0 001-1v-2h-1v2H3v-2H2z","fillOpacity":0.9}}]};

const UploadIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'upload',
    icon: element,
  },
);

UploadIcon.displayName = 'UploadIcon';

export default UploadIcon;
