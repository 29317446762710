// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M15 8A7 7 0 101 8a7 7 0 0014 0zM5.67 4.95L8 7.29l2.33-2.34.7.7L8.7 8l2.34 2.35-.71.7L8 8.71l-2.33 2.34-.7-.7L7.3 8 4.96 5.65l.71-.7z","fillOpacity":0.9}}]};

const CloseCircleFilledIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'close-circle-filled',
    icon: element,
  },
);

CloseCircleFilledIcon.displayName = 'CloseCircleFilledIcon';

export default CloseCircleFilledIcon;
