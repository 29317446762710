// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M11 8a3 3 0 11-6 0 3 3 0 016 0zm-1 0a2 2 0 10-4 0 2 2 0 004 0z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M8 1.25l6.06 3.38v6.75L8 14.75l-6.06-3.38V4.63L8 1.25zM2.94 5.21v5.58L8 13.6l5.06-2.82V5.2L8 2.4 2.94 5.21z","fillOpacity":0.9}}]};

const SettingIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'setting',
    icon: element,
  },
);

SettingIcon.displayName = 'SettingIcon';

export default SettingIcon;
