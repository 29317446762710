// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M12.46 9.54l-.92.92L8 6.92l-3.54 3.54-.92-.92L8 5.08l4.46 4.46z","fillOpacity":0.9}}]};

const ChevronUpIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-up',
    icon: element,
  },
);

ChevronUpIcon.displayName = 'ChevronUpIcon';

export default ChevronUpIcon;
