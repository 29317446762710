// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M7.5 4v4.39L10 11l.7-.7-2.2-2.33V4h-1z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M15 8A7 7 0 111 8a7 7 0 0114 0zm-1 0A6 6 0 102 8a6 6 0 0012 0z","fillOpacity":0.9}}]};

const TimeIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'time',
    icon: element,
  },
);

TimeIcon.displayName = 'TimeIcon';

export default TimeIcon;
