// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M6.46 12.46l-.92-.92L9.08 8 5.54 4.46l.92-.92L10.92 8l-4.46 4.46z","fillOpacity":0.9}}]};

const ChevronRightIcon = (props: IconProps) => createElement(
  IconBase,
  {
    ...props,
    id: 'chevron-right',
    icon: element,
  },
);

ChevronRightIcon.displayName = 'ChevronRightIcon';

export default ChevronRightIcon;
